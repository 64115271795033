import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ILicenseData } from '../../../interfaces/ILicenseData';
import { parse } from 'path';

const PackageLicenseTag: React.FunctionComponent<any> = (props) => {
  console.log(props)
  const [packageList, setPackageList] = useState<any[]>([]);

  useEffect(() => {
    if (props.application?.packageJson) {
      try {
        const parsedData = JSON.parse(props.application.packageJson);
        console.log(parsedData)
        setPackageList(parsedData);
        console.log(parsedData)
      } catch (error) {
        console.error('packageJson parse error:', error);
      }
    }
  }, [props.application]);

console.log(props)

  return (
    <Form.Item
      label="Package Licence"
      name="packageId"
      initialValue={props.value}
      rules={[
        {
          required: true,
          message: 'Please select your Package Id!',
        },
      ]}
    >
      <Select placeholder="Select a package">
        {packageList.map((pkg) => (
          <Select.Option key={pkg.packageId} value={pkg.packageId}>
            {pkg.packageName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PackageLicenseTag;
