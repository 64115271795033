import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Stack, TextField, Popover, IconButton } from '@mui/material';
import { Form, Input, Popconfirm } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IApplication, IApplicationData } from '../../../interfaces/IApplicationData';

const initialColumns = [
  { field: 'packageId', headerName: 'Paket Id', width: 70, editable: false, columnId: 1 },
  { field: 'packageName', headerName: 'Paket Adı', width: 200, editable: true, columnId: 2 },
];

const initialRows = [ ];

export interface LicencePackageDatagridRef {
  GetAllData: () => any[];
}

interface LicencePackageDatagridProps {
  data:any;
}

const LicencePackageDatagrid = forwardRef<LicencePackageDatagridRef, LicencePackageDatagridProps>((props, ref) => {
  
    const [rows, setRows] = useState<any[]>(initialRows);
    const [columns, setColumns] = useState<any[]>(initialColumns);
    const [editingHeader, setEditingHeader] = useState<string | null>(null);

    useEffect(() => {
      if (props?.data != null) {
        const parsedData = JSON.parse(props.data);
    
        const filteredData = parsedData.map((row, index) => {
          const { packageId, ...rest } = row;
          return { rowId: index + 1, packageId, ...rest }; 
        });
    
        if (filteredData.length > 0) {
          const columnNames = Object.keys(filteredData[0]);
          
          const newColumns = columnNames
            .filter(column => column !== "RowId") 
            .map(column => ({
              field: column,
              headerName: column,
              width: 200,
              editable: true,
            }));
          
          console.log(newColumns)
          setColumns(newColumns); 
        }
        console.log(filteredData)
        setRows(filteredData); 
      }
    }, [props?.data]);
    
    
    useImperativeHandle(ref, () => ({
      GetAllData: () => {
        // Create a mapping of field names to header names
        const headerMapping = columns.reduce((acc, column) => {
          
          if(column.field == "packageName" || column.field == "packageId"){
            acc[column.field] = column.field;
          }
          else{
            acc[column.field] = column.headerName;
          }
          return acc;
        }, {});

        // Convert rows to a desired format dynamically
        const result = rows.map(row => {
          const convertedRow = {};
          Object.keys(row).forEach(key => {
            // Replace the key with the corresponding headerName
            convertedRow[headerMapping[key] || key] = row[key];
          });
          return convertedRow;
        });
        return result; // Return the converted data
      }
    }));

    const handleAddRow = () => {
      const newRow = {
        rowId: rows.length + 1,
        packageId: rows.length +1,
        packageName: `New ${rows.length + 1}`,
      };
      setRows([...rows, newRow]);
    };

    const handleAddColumn = () => {
      const columnName = `Column ${columns.length + 1}`;
      const newColumn = {
        field: columnName.replace(" ",""),
        headerName: columnName,
        width: 200,
        editable: true
      };
      setColumns([...columns, newColumn]);

      const updatedRows = rows.map(row => ({
        ...row,
        [newColumn.field]: `Value`
      }));
      setRows(updatedRows);
    };

    const handleProcessRowUpdate = (newRow, oldRow) => {
      const rowIndex = rows.findIndex((row) => row.rowId === oldRow.rowId);
      const updatedRows = [...rows];
      updatedRows[rowIndex] = { ...newRow };
      setRows(updatedRows);
      return newRow;
    };


    const handleDeleteColumn = (field: string) => {
      if (field === 'packageName' ||field === 'packageId') {
        return;
      }
      setColumns(columns.filter(col => col.field !== field));
      setRows(rows.map(row => {
        const { [field]: removedField, ...rest } = row;
        return rest;
      }));
    };

    const handleDeleteRow = (rowId: number) => {
      setRows(rows.filter(row => row.rowId !== rowId));
    };

    const handleMoveRow = (rowId: number, direction: 'up' | 'down') => {
      const index = rows.findIndex(row => row.rowId === rowId);
      const newRows = [...rows];
      if (direction === 'up' && index > 0) {
        [newRows[index], newRows[index - 1]] = [newRows[index - 1], newRows[index]];
      } else if (direction === 'down' && index < rows.length - 1) {
        [newRows[index], newRows[index + 1]] = [newRows[index + 1], newRows[index]];
      }
      setRows(newRows);
    };

    const handleHeaderEdit = (field: string, newValue: string) => {
      const updatedColumns = columns.map(column => column.field === field ? { ...column, headerName: newValue } : column);
      setColumns(updatedColumns);

      setEditingHeader(null);
    };

    const updatedColumns = [
      ...columns.filter(col => col.field !== 'rowId')
        .map((col, index) => ({
          ...col,
          columnId: index + 1,
          //editable: col.field === 'packageName',
          renderHeader: (params) => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {editingHeader === col.field ? (
                <input
                  autoFocus
                  defaultValue={col.headerName}
                  onBlur={(e) => handleHeaderEdit(col.field, e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleHeaderEdit(col.field, e.currentTarget.value);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: '100px' }}
                />
              ) : (
                <div 
                  onDoubleClick={() => col.field !== 'packageId' && col.field !== 'packageName' && setEditingHeader(col.field)}
                  style={{ cursor: col.field !== 'packageId' && col.field !== 'packageName' ? 'pointer' : 'default' }}
                >
                  {col.field === 'packageId' ? 'Paket Id' :
                   col.field === 'packageName' ? 'Paket Adı' :
                   col.headerName}
                </div>
              )}
              {(col.field !== 'packageName' && col.field !== 'packageId') && (
                <Popconfirm
                  title="Bu kolonu silmek istediğinizden emin misiniz?"
                  onConfirm={() => handleDeleteColumn(col.field)}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <IconButton size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Popconfirm>
              )}
            </div>
          )
        })),
      {
        field: 'actions',
        headerName: 'İşlemler',
        width: 200,
        sortable: false,
        renderCell: (params) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Popconfirm
              title="Bu satırı silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDeleteRow(params.row.rowId)}
              okText="Evet"
              cancelText="Hayır"
            >
              <IconButton size="small">
                <DeleteOutlineIcon />
              </IconButton>
            </Popconfirm>
            <IconButton 
              size="small" 
              onClick={() => handleMoveRow(params.row.rowId, 'up')}
              disabled={params.row.rowId === 1}
            >
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleMoveRow(params.row.rowId, 'down')}
              disabled={params.row.rowId === rows.length}
            >
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      }
    ];
    
    return (
      <Box sx={{ 
        height: 400, 
        width: '100%',
        marginTop: '30px',    // Üst boşluk
        marginBottom: '30px'  // Alt boşluk
      }}>
        <DataGrid
          rows={rows}
          columns={updatedColumns}
          getRowId={(row) => row.rowId}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
          checkboxSelection
          processRowUpdate={handleProcessRowUpdate}
          slots={{
            footer: () => (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  padding: '10px',
                  justifyContent: 'space-between',
                  borderTop: '1px solid rgba(224, 224, 224, 1)'
                }}
              >
                <Button 
                  onClick={handleAddRow} 
                  variant="contained"
                >
                  Add Row
                </Button>
                
                <Button 
                  onClick={handleAddColumn} 
                  variant="contained"
                >
                  Add Column
                </Button>
              </Stack>
            )
          }}
        />
      </Box>
    );
  }
);


export default LicencePackageDatagrid;
